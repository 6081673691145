var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "qualityInspectionCategoryList-wrapper",
    },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportMethod: _vm.exportMethod,
          searchParams: _vm.searchParams,
          isMultiSelect: false,
          headers: _vm.headers,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "配置类目", permission: "configCategory" },
                  on: { click: _vm.goCategory },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-select", {
                  attrs: {
                    label: "一级类目",
                    filterable: "",
                    clearable: "",
                    valueType: "string,number",
                    options: _vm.levelOneCategoryOps,
                  },
                  model: {
                    value: _vm.searchParams.idOne,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "idOne", $$v)
                    },
                    expression: "searchParams.idOne",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "二级类目",
                    filterable: "",
                    clearable: "",
                    valueType: "string,number",
                    options: _vm.levelTwoCategoryOps,
                  },
                  model: {
                    value: _vm.searchParams.idTwo,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "idTwo", $$v)
                    },
                    expression: "searchParams.idTwo",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "三级类目",
                    filterable: "",
                    clearable: "",
                    valueType: "string,number",
                    options: _vm.levelThreeCategoryOps,
                  },
                  model: {
                    value: _vm.searchParams.idThree,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "idThree", $$v)
                    },
                    expression: "searchParams.idThree",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.categoryStatusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    type: "rangedatetimer",
                    label: "创建时间",
                    format: "YYYY-MM-DD HH:mm",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _vm.isShowDeleteBtn(scope.row)
                  ? _c("v-button", {
                      attrs: {
                        permission: "status",
                        text: scope.row.status === 0 ? "关闭" : "开启",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateStatus(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }