<!--
 * @Description: 品质管理-品质 检查类目 管理
 * @Author: 马俊
 * @Date: 2021-04-26 17:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityInspectionCategoryList-wrapper" v-loading="loading">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="配置类目" permission="configCategory" @click="goCategory"></v-button>
      </template>

      <template #searchSlot>
        <v-select
          label="一级类目"
          v-model="searchParams.idOne"
          filterable
          clearable
          valueType="string,number"
          :options="levelOneCategoryOps"
        />
        <v-select
          label="二级类目"
          v-model="searchParams.idTwo"
          filterable
          clearable
          valueType="string,number"
          :options="levelTwoCategoryOps"
        />
        <v-select
          label="三级类目"
          v-model="searchParams.idThree"
          filterable
          clearable
          valueType="string,number"
          :options="levelThreeCategoryOps"
        />
        <v-select label="状态" v-model="searchParams.status" :options="categoryStatusOps"/>
        <v-datepicker
          type="rangedatetimer"
          label="创建时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="isShowDeleteBtn(scope.row)" permission="status" :text="scope.row.status === 0 ? '关闭' : '开启'" type="text" @click="updateStatus(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckCategoryListURL,
  getItemNumberByCategoryIdURL,
  getCheckCategoryListSelectURL,
  closeOrOpenQualityCheckCategoryByIdURL
} from './api'
import {
  categoryStatusOps,
  categoryStatusMap
} from './map'
import { regionParams } from 'common/select2Params'
// import { mapHelper } from 'common/utils'

export default {
  name: 'qualityInspectionCategoryList',
  components: {
  },
  data () {
    return {
      loading: false,
      searchUrl: getQualityCheckCategoryListURL,
      exportUrl: 'xx',
      exportMethod: 'get',
      searchParams: {
        idOne: undefined,
        idTwo: undefined,
        idThree: undefined,
        status: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'categoryName1',
          label: '一级类目'
        },
        {
          prop: 'categoryName2',
          label: '二级类目'
        },
        {
          prop: 'categoryName3',
          label: '三级类目'
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return categoryStatusMap[row.status]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      regionParams,
      levelOneCategoryList: [],
      levelTwoCategoryList: [],
      levelThreeCategoryList: [],
      categoryStatusOps: categoryStatusOps(1)
    }
  },
  computed: {
    idOne () {
      return this.searchParams.idOne
    },
    idTwo () {
      return this.searchParams.idTwo
    },
    levelOneCategoryOps () {
      let ops = this.levelOneCategoryList
      return this.levelCategoryToOps(ops)
    },
    levelTwoCategoryOps () {
      let ops = this.levelTwoCategoryList
      return this.levelCategoryToOps(ops)
    },
    levelThreeCategoryOps () {
      let ops = this.levelThreeCategoryList
      return this.levelCategoryToOps(ops)
    },
    userType () {
      return this.$store.state.userInfo.userType
    },
  },
  watch: {
    idOne: {
      handler (val) {
        this.levelTwoCategoryList = []
        this.searchParams.idTwo = undefined
        this.levelThreeCategoryList = []
        this.searchParams.idThree = undefined
        if (!val) {
          return
        }
        this.getLevelCategoryList('levelTwoCategoryList', { id: val, categoryLevel: 2 })
      }
    },
    idTwo: {
      handler (val) {
        this.levelThreeCategoryList = []
        this.searchParams.idThree = undefined
        if (!val) {
          return
        }
        this.getLevelCategoryList('levelThreeCategoryList', { id: val, categoryLevel: 3 })
      }
    }
  },
  created () {
    // this.$setBreadcrumb('资产类目管理')
    // 获取一级类目列表
    this.getLevelCategoryList('levelOneCategoryList', { categoryLevel: 1 })
  },
  methods: {
    goCategory () {
      // 跳转配置类目页面
      this.$router.push({
        name: 'qualityConfigCategoryList'
      })
    },

    updateStatus (row) {
      let message = `是否${row.status === 0 ? '关闭' : '开启'}此条数据,请确认操作！`
      let level = row.categoryLevel
      this.$confirm(message).then(async (result) => {
        if (!result) {
          return
        }
        // 1. 先查询类目关联的资产数量
        if (row.status === 0) { // 0 开启->关闭 1 关闭->开启
          let res = null
          this.loading = true
          res = await this.$axios.get(getItemNumberByCategoryIdURL, {
            params: {
              id: row.id,
              categoryLevel: level
            }
          })
          if (res && res.data !== 0) {
            this.loading = false
            let msg = '无法关闭此类目，请先取消关联检查标准库！'
            this.$confirm(msg)
            return
          } else if (!res || res.data === null) {
            return
          }
        }
        // 2. 关闭类目
        this.$axios.post(closeOrOpenQualityCheckCategoryByIdURL, {
          id: row.id,
          status: row.status === 0 ? 1 : 0
        }).then((res) => {
          if (res.status === 100) {
            // 更新成功后
            this.$refs.list.searchData()
            this.$message.success('操作成功')
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    // 获取类目数据
    getLevelCategoryList (str, params) {
      /* str - 类目列表字段 val - 上一类目的参数值 */
      this.$axios.get(getCheckCategoryListSelectURL, {
        params: {
          ...params,
          curPage: 1,
          pageSize: 10000000
        }
      }).then((res) => {
        if (res.status === 100) {
          if (res.data) {
            this[str] = res.data
          }
        }
      })
    },

    // 一级、二级、三级类目数据格式转换
    levelCategoryToOps (arr) {
      let list = []
      arr.forEach(item => {
        list.push({
          text: item.categoryName,
          value: item.id
        })
      })
      return list
    },

    isShowDeleteBtn (row) {
      if (this.userType == '106') {
        return true
      }
      if (this.userType == '102' && row.hierarchy == '1') {
        return true
      }
      if (this.userType == '100') {
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  max-height: 30vh;
  overflow: auto;
  text-align: left;
  padding-left: 1px;
  .sign-item {
    padding-bottom: 5px!important;
  }
}
</style>
